import { mapState, mapActions } from 'vuex'
import SCard from '../../../components/ui/s-card'
import SLink from '../../../components/ui/s-link'

export default {
  name: 'MyArchive',

  components: {
    SCard,
    SLink,
    ReportCard: () => import('../../../components/ReportCard'),
  },

  data() {
    return {
      records: [],
      filter: this.$attrs.status || 'ALL',
      showError: false,
    }
  },

  async created() {
    const { reports } = await this.getArchivedReports()
    reports.map((report) => this.records.push(report))
  },

  computed: mapState(['loading', 'error']),

  methods: {
    ...mapActions(['getArchivedReports']),

    reload: () => window.location.reload(),
  },

  watch: {
    error() {
      this.showError = !!this.error
    },
  },
}
